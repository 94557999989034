.learnMore-main-container {
  /* Main div */
}
.learnMore-boarding {
  position: relative;
  padding-top: 12vh;
  height: 40vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}
.learnMore-boarding-img {
  position: absolute;
  top: 12vh;
  width: 100%;
  z-index: -1;
  object-fit: cover;
}
.learnMore-boarding-text {
  width: 55vw;
  margin-left: 7.5vw;
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.53);
  font-family: Inter;
  font-size: 5.3vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.learnMore-Info {
  margin-top: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.learnMore-Info-box {
  width: 92vw;
  margin-bottom: 2.75rem;
}
.learnMore-info-box-question {
  margin-bottom: 1rem;
  color: #000;
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.03rem;
}
.learnMore-info-box-answer {
  color: #000;
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.03rem;
}
.FAQ-WRAP{
  display: flex;
  flex-direction: column;
}
.FAQ-text{
  color: var(--Black, #000);
  text-align: center;
  font-family: Inter;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.6px;
  margin-top: 3vh;
}

.faq-section {
  font-family: Arial, sans-serif;
  margin: 20px auto;
  /* max-width: 700px; */
}

.faq-item {
  padding: 15px 10px;
}

.faq-item:last-child {
  border-bottom: none;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  color: #333;
  border-radius: 12px;
  background: rgba(126, 215, 194, 0.3);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);

  width: 50vw;

  padding: 25px;

  align-items: center;
  gap: 40px;
  flex-shrink: 0;
}

/* .faq-question:hover {
  color: #007bff;
} */

.faq-answer {
  font-size: 0.9rem;
  color: #555;
  margin-top: 10px;
  line-height: 1.6;
  display: flex;
  justify-content: space-between;
  align-items: center;
padding: 2%;
  border-radius: 12px;
  background: #E7F6EC;
  width: 50vw;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
  
}

.plus-icon {
  font-size: 1.2rem;
  color: #ffffff;
  border-radius: 0px 8px 8px 0px;
  background: var(--Black, #152329);
  backdrop-filter: blur(7.5px);
  display: flex;
  width: 0.3vw;

  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.minus-icon {
  cursor: pointer;
  font-size: 1.2rem;
  color: #ffffff;
  border-radius: 0px 8px 0px 0px;
  background: var(--Black, #152329);
  backdrop-filter: blur(7.5px);
  display: flex;
  width: 0.3vw;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  margin-bottom: 12vh;
}

.minus-icon:hover {
  color: #ff0000;
  transition: all 300ms ease-in-out;
}
@media(max-width: 916px){
  .learnMore-info-box-question,
  .learnMore-info-box-answer {
    font-size: 1.2rem;
  }

  .learnMore-boarding {
    height: 26rem;
  }
  .learnMore-boarding-img {
    height: 17rem;
  }
  .learnMore-boarding-text {
    width: 73vw;
    margin-left: 3.5vw;
    font-size: 2.75rem;
  }
  .learnMore-info-box-answer {
font-size:1rem;
  }
  .faq-section{
    width: 100%;
  }
  .faq-item {
    padding: 4% 7%;
    width: 100%;
}
.faq-question {
  width: auto;
  border-radius: 12px;
padding: 5%;
}
.faq-answer {
  width: 100%;
}
.FAQ-WRAP{
  margin-top: -4%;
}
}