.Reports-page-container {
}
.Reports-page-boarding {
  position: relative;
  padding-top: 12vh;
  height: 40vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}
.Reports-boarding-img {
  position: absolute;
  top: 12vh;
  width: 100%;
  z-index: -1;
  object-fit: cover;
}
.Reports-page-text {
  width: 55vw;
  margin-left: 7.5vw;
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.53);
 
  font-size: 5.3vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.Reports-page-text2 {
  color: #000;
  font-size: 1.2rem;
  padding: 0 5vw 1vw 3vw;
}
.FDT-wrap {
  display: flex;
  flex-direction: row;
  padding: 0 0 1vw 3vw;
  gap: 2vw;
  width: fit-content;
  justify-content: space-between;
}
.Filter-wrap {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  height: 3vw;
  width: 10vw;
  align-items: center;
  justify-content: center;
  gap:12px

}
.Date-wrap{
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  height: 3vw;
  width: 10vw;
  align-items: center;
  justify-content: center;
  gap:12px
}
.Time-wrap{
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  height: 3vw;
  width: 10vw;
  align-items: center;
  justify-content: center;
  gap:12px
}
.Report_wrap{
  padding: 0 5vw 1vw 3vw;
}
.table-container {
  overflow-x: auto;
  margin: 20px 0;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  font-family: Arial, sans-serif;
  margin: 0 auto;
  margin-bottom: 2vw;
}

.custom-table th {
  width: 20%;
  color: var(--Black, #000);
  padding: 20px 10px 15px 10px;
  /* margin-bottom: 20px;  */
  border-right: 2px solid rgba(117, 115, 115, 0.80);
  background: #F3E6E9;
  font-weight: bold;
  /* margin-bottom: 10vw; */
}

.custom-table th:last-child {
  border-right: none;
}

.custom-table td {
  padding: 20px 10px 15px 10px;
  /* border-bottom-left-radius: 8px; */
  border-right: 2px solid #ddd;
}

.custom-table td:last-child {
  border-right: none;
}


.Download-wrap
{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  
}
.Download-text{
  color: #36518F;
}
.Download{
  cursor: pointer;
  border-radius: 50px;
  background: rgba(242, 153, 74, 0.20);
}

/* Button styles */
.download-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}
.mobile-view {
  display: none;
}

/* .download-btn:hover {
  background-color: #0056b3;
}

.download-btn:active {
  background-color: #003f7f;
} */
@media (max-width: 916px) {

.Reports-page-boarding {
  position: relative;
  padding-top: 0vh;
  height: 30vh;

}
.Reports-boarding-img {
  position: absolute;
  top: 0vh;
  width: auto;
  z-index: -1;
  object-fit: cover;
  height: 100%;
}
.Reports-page-text {
  font-size: 1.85rem;
  margin-left: 2%;
  margin-top: 10%;
  text-align: center;
}
.Reports-page-text2 {
  color: #000;
  font-size: 1rem;
  padding: 0 5vw 1vw 3vw;
  line-height: 41px;
}
.FDT-wrap {
  display: flex;
  flex-direction: row;
  padding: 0;
  width: 90%;
  justify-content: space-between;
  padding-left: 5%;
  /* padding-right: 5%; */ 
}
.Date-wrap,.Filter-wrap,.Time-wrap{
  height: fit-content;
  width: fit-content;
  padding: 2%;
}
.mobile-view {
  display: block;
}

.mobile-card {
  background: #fff;
  padding: 1rem;
  border-radius: 8px;
border-top: 5px solid #F3E6E9;
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
margin: 1rem;
}

.mobile-card-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  border-bottom: 1px solid rgb(192, 190, 190);
}

.mobile-card-body p {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  color: #333;
}

.mobile-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10%;

}

.download-text {
  color: #007bff;
  cursor: pointer;
  border: none;
  background: none;
}

.copy-icon {
  width: 16px;
  height: 16px;
  margin-left: 0.5rem;
}
.mobile-body{
  display: flex;
  justify-content: space-between;
}
.id_icon{
  display: flex;
}
.Report_wrap{
  display:none;
}
.newsletter input {
  width: 90%;
}
.newsletter button {
width:50%
}
}