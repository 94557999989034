.container {
  width: 100%;
  background-color: #F3E6E9;
}
.RDWrap{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* padding-top: 2vh; */
  margin: 0 5%;
  margin-bottom: 2%;
  margin-top: 100px;
  /* border: 1px solid black; */
  
}
.DownloadWrap{
  padding: 0.5% 2%;
  font-size: 2vw;
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.Dtext{
  color: #282626;
text-align: center;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 133.333% */
text-transform: uppercase;
}

.heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  font-size: 2vw;
  font-weight: 550;
  margin-bottom: 2%;
  margin: 0 5%;
}
.profile {
  display: flex;
  flex-direction: column;
  max-width: 50%;
  border: 1 px solid black;
  justify-content: center;
  width:fit-content;
}
.profileh1 {
  color: #000;
  font-family: Inter;
  font-size: 1.65rem;
  font-style: normal;
  font-weight: 500;
}
.profileh,
.profileh2 {
  color: #757373;
  font-family: Inter;
  font-size: 1.15rem;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 1%;
}
.profileh2 {
  margin-top: 6%;
  margin-bottom: 0%;
  font-size: 1.05rem;
}
.profileCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5%;
  margin-bottom: 2%;
  margin-left: 5%;
  margin-right: 5%;
}

.profileInfo {
  display: flex;
  align-items: center;
  gap: 2%;
  width: 40%;
}

.profileImage {
  width: 15%;
  height: auto;
  border-radius: 50%;
}

.resdetails {
  width: 60%;
  display: flex;
  justify-content: space-around;
}

.table {
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2%;
  border: 1px solid #e9e5e5;
  background: var(--Base-White, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-left: 5%;
  margin-right: 5%;
}
.table3 {
  display: flex;
  margin-left: 10%;
  margin-right: 10%;
  border-bottom: 1px solid rgba(204, 190, 190, 0.39);
  background: #fff;
  justify-content: space-around;
  padding: 1.5%;
  margin-top: 0%;
}

.tableHeader,
.tableRow {
  display: grid;
  align-items: center;
  grid-template-columns: 40% 20% 20% 20%;
  padding: 1% 3% 1% 0;
  border-bottom: 1px solid #ddd;
}
.tableRow {
  border-left: 2px solid #ddd;
  border-right: 2px solid #ddd;
}

.tableHeader {
  font-weight: bold;
  background-color: #f0f0f0;
  border-bottom: 1px solid #085946;
  background: #f3f1f1;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 1%;
}

/* .tableRow:nth-child(even) {
    background-color: #f9f9f9;
  } */

.tableRow div {
  text-align: center;
  font-size: 1rem;
}
.tableHeader div {
  text-align: center;
  text-transform: uppercase;
  color: #000;
  font-feature-settings: "cpsp" on;
  /* font-family: Inter; */
  font-style: normal;
  font-weight: 600;
  padding: 0.5rem 0;
  font-size: 1rem;
  line-height: 16px; /* 66.667% */
  letter-spacing: 0.05em;
}
.thead {
  color: #4b4b4b;
  font-feature-settings: "cpsp" on;
  /* font-family: Inter; */
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  letter-spacing: 0.48px;
  text-transform: uppercase;
  display: flex;
  justify-content: flex-start;
  padding-left: 10%;
  /* Align text inside to the left */
}
.resulthead {
  color: #085946;
  text-align: center;
  font-feature-settings: "cpsp" on;
  /* font-family: Inter; */
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 50% */
  letter-spacing: 0.64px;
  margin-top: 2%;
}
/* Table Section */
.resultTable {
  width: 80%;
  border-collapse: collapse;
  margin-bottom: 2vw;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-left: 10%;
  margin-right: 10%;
}

.resultTable th,
.resultTable td {
  padding: 1vw;
  border-bottom: 1px solid #d3d3d3;
  text-align: left;
}

/* Guidelines Section */
.guidelines {
  margin: 0 10% 5% 10%;
}

.guidelines h3 {
  margin-bottom: 1vw;
  color: #4b4b4b;
}

.subHeading {
  margin-bottom: 1.5vw;
  margin-top: 1vw;
  font-weight: 550;
}
.guidelinesContent {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.guidelinesContent h4 {
  background-color: #ffffff;

  margin-bottom: 0.5vw;
  color: #4b4b4b;
}

.guidelinesContent p {
  margin-bottom: 1vw;
}
/* Guidelines Group */
.guidelinesContent {
  display: flex;
  flex-direction: column;
  gap: 1vw;
  padding: 1%;
}

.guidelinesGroup {
  display: flex;
  flex-direction: row;
  background-color: #f9f9f9;
  padding: 1vw;
  border-radius: 6px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.time {
  font-weight: bold;
  color: #4b4b4b;
  margin-bottom: 0.5vw;
  width: 40%;
  padding: 2%;
}

.details {
  color: #6b6b6b;
  width: 55%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  line-height: 1.52rem; /* 133.333% */
  letter-spacing: 0.48px;
  /* font-family: Inter; */
}
.diet {
  color: #085946;
  text-align: center;
  font-feature-settings: "cpsp" on;
  /* font-family: Inter; */
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.64px;
  text-transform: capitalize;
  margin-top: 2%;
}
.detox {
  margin-top: 2%;
  line-height: 28px; /* 145.833% */
  letter-spacing: 0.48px;
}
.selector {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

/* Buttons for selecting pakriti */
.button {
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f5f5f5;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

/* Active button styling */
.active {
  background-color: #007bff;
  color: #fff;
}

/* Card style for the table container */
.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 10px;
  margin-bottom: 1%;
}

/* Table styles */
.pakrititable {
  width: 100%;
  border-collapse: collapse;
  /* margin-top: 20px; */
}

.pakrititable th,
.pakrititable td {
  border: 1px solid #ddd;
  padding: 10px;
  /* text-align: left; */
  color: #000;
  font-feature-settings: "cpsp" on;
  /* font-family: Inter; */
  font-style: normal;
  font-weight: 500;
  line-height: 25px; /* 104.167% */
  letter-spacing: 0.48px;
  text-transform: capitalize;
}

.pakrititable td {
  text-align: left;
}

.pakrititable th {
  background-color: #f8f8f8;
  font-weight: bold;
  /* text-align: start; */
  border-radius: 4px 4px 0px 0px;
  border-bottom: 3px solid #085946;
}

.food {
  font-weight: 550 !important;
  /* text-align: left !important; */
}

.lasthead {
  color: #000;
  text-align: center;
  font-feature-settings: "cpsp" on;
  /* font-family: Inter; */
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 50% */
  letter-spacing: 0.64px;
  text-transform: capitalize;
  margin-top: 3%;
}

.recline {
  color: #085946;
  text-align: center;
  /* font-family: Inter; */
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 57.143% */
  letter-spacing: 0.56px;
  margin-top: 5%;
}
.recline2 {
  text-align: center;
  /* font-family: Inter; */
  font-size: 18px;
  font-style: normal;
  line-height: 16px; /* 57.143% */
  letter-spacing: 0.56px;
  margin-top: 2%;
  margin-bottom: 2%;
}
.mobileSliderContainer{
  display: none;
  margin-top: 5%;
}
.containerMobile {
  display: none;
}
.profileContainerMobile{
  display: none;
}
.detoxhead{
  color: #000;

font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 700;
line-height: 25px; /* 134.615% */
letter-spacing: 0.52px;
text-decoration-line: underline;
text-decoration-style: solid;
text-decoration-skip-ink: auto;
text-decoration-thickness: auto;
text-underline-offset: auto;
text-underline-position: from-font;
}
@media (max-width: 916px) {
  .mobileSliderContainer{
    
    display: block;
    /* margin-left: 2%;
    margin-right: 2%; */
  }
  .containerMobile {
    display: block;
  }
  .guidelinesContent{
    display: none;
  }
  .profileCard {
    flex-direction: column;
    justify-content: left;
  }
  .profileInfo {
    width: 100%;
  }
  .resdetails {
    width: 100%;
    margin-top: 5%;
    margin-bottom: 2%;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.14);
    padding: 1%;
  }
  .profileh2 {
    font-size: 0.9rem;
  }
  .profileh1 {
    font-size: 1rem;
  }
  .tableHeader,
  .tableRow {
    grid-template-columns: 35% 20% 20% 25%;
  }
  .tableRow div {
    text-align: left;
    font-size: 0.65rem;
  }
  .tableHeader div {
    font-size: 0.8rem;
    text-align: left;

  }
  .thead {
    font-size: 0.56rem;
    padding-left: 5%;
  }
  .resulthead {
    font-size: 1rem;
    margin-top: 8%;
    margin-bottom: 5%;
  }
  .table3 {
    margin-bottom: 5%;
  }
  .subHeading {
    margin-bottom: 10%;
    margin-top: 10%;
  }
  .diet {
    font-size: 1.2rem;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .recline {
    margin-top: 8%;
    text-align: left;
  }
  .pakrititable th,
  .pakrititable td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
    color: #4b4b4b;
    font-feature-settings: "cpsp" on;
    /* font-family: Inter; */
    font-style: normal;
    font-weight: 500;
    line-height: 25px; /* 104.167% */
    letter-spacing: 0.48px;
    text-transform: capitalize;
    font-size: 0.6rem;
  }

  .pakrititable th {
    background-color: #f8f8f8;
    font-weight: bold;
    text-align: center;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 3px solid #085946;
    font-size: 0.8rem;
  }
  .pakrititable{
    margin-top: 5%;
  }
  .lasthead {
    margin-top: 10%;
    margin-bottom: 5%;
  }
 
  .guidelines {
    margin-left: 5%;
    margin-right: 5%;
  }
  .heading {
    font-size: 1.9rem;
  }
  /* .mobileSliderContainer {
    padding: 0px;
    text-align: center;
    border-radius: 8px;
    margin: auto;
  } */
   .guidelinesh{
    text-align: left;
   }
  .recline2 {
    line-height: 25px;
  }
  .mobileGuidelinesContent {
    margin-bottom: 20px;
  }
  
  .mobileGuidelinesGroup {
    display: flex;
    flex-direction: column;
    padding: 27px 20px;
    justify-content: center;
    align-items: flex-start;
    border-bottom: 1px solid rgba(204, 190, 190, 0.71);
    background: #FFF;
   

    }
  
  .mobileTime {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 7%;
    color: #4B4B4B;
font-feature-settings: 'cpsp' on;
font-family: Inter;
font-style: normal;
font-weight: 600;
line-height: 16px; /* 88.889% */
letter-spacing: 0.36px;
text-transform: capitalize;
text-align: left;
  }
  
  .mobileDetails {
    font-size: 1rem;
    color: rgba(110, 111, 113, 0.91);
font-family: Inter;
font-style: normal;
font-weight: 500;
line-height: 32px; /* 200% */
letter-spacing: 0.32px
  }
  
  .mobileNavigationButtons {
    display: flex;
    justify-content: space-between;
  }
  
  .mobileButton {
    padding: 10px 20px;
    font-size: 16px;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  .detox {
    margin-top: 5%;
    font-size: 0.85rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;    letter-spacing: 0.38px;
  }
  /* Container */
.containerMobile {
  padding: 2vw;
  border-radius: 1vw;
  margin: 0 auto;
  text-align: center;
}

/* Heading */
.headingMobile {
  font-size: 5vw;
  margin-bottom: 4vw;
  margin-top: 5%;

}

/* Dropdown */


/* Table */
.tableMobile {
  display: grid;
  background-color: white;
  border-radius: 1vw;
}

/* Row Header */
.headerMobile {
  font-weight: bold;
  padding: 2%;
  border-bottom: 1px solid #e0e0e0;
  font-size: 0.85rem;

}

/* Cell */
.rowMobile ,.rowMobilehead {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.rowMobilehead{
  border-radius: 4px 4px 0px 0px;
  border-bottom: 3px solid #085946;
  background: #F3F1F1;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);}


.cellMobile {
  padding: 2vw;
  font-size: 3.5vw;
  border-right: 1px solid #e0e0e0;
  text-align: left;

}

.cellMobile:last-child {
  border-right: none;
}
.pakrititable{
  display: none;
}
.lasthead {
font-size: 1.35rem;
}
.profileContainerMobile {
  padding: 20px;
  margin: 20px auto;
  display: block;
}

.profileHeaderMobile {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: row;
  border-radius: 4px;
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.14);
padding-left: 1%;
}

.profileImageMobile {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 20px;
}

.userInfoMobile h2 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}

.userInfoMobile p {
  margin: 5px 0;
  color: #555;
}

.profileDetailsMobile {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.detailsItemMobile p {
  margin: 5px 0;
  color: #777;
}

.detailsItemMobile p:nth-child(2) {
  font-weight: 600;
}
.userInfoMobile{
  display: flex;
          justify-content: space-between;
          flex-direction: row;
          /* gap: 5rem; */
          align-items: center;
          padding: 1% 5%;
          /* background-color: red; */
          width: 100%;
          margin: 0%;
}
.profileCard{
  display: none;
}
.profileh{
  font-size: 0.9rem;
}
}