:root {
  --diameter: 22vw;
  --bit: calc(var(--diameter) / 300);
  --diameter-large: 60vw;
  --bit-large: calc(var(--diameter-large) / 300);
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1vw;
  height: 100%;
}

.webcamrecorder {
  width: var(--diameter);
  height: var(--diameter);
  overflow: visible;
}

.circularframe {
  width: 95%;
  height: 95%;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #fff;
  transition: border-color 0.5s ease-in-out;
  position: relative;
  left: calc(8 * var(--bit));
  top: calc(35 * var(--bit));
}

.webcam {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.webcamOverlay {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  z-index: 100;
  position: absolute;
  /* background-color: rgba(5, 5, 5, 0.2); */
  /* transform: translateX(-16.66%); */
}

.videopreview {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.controls {
  margin-top: calc(20 * var(--bit));
}

.progressbar {
  margin-top: calc(20 * var(--bit));
  width: 100%;
  position: relative;
  top: calc(-100% + calc(21.5 * var(--bit)));
}

.same-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 912px) {
  .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1vw;
    height: 100%;
  }

  .webcamrecorder {
    width: var(--diameter-large);
    height: var(--diameter-large);
    overflow: visible;
  }

  .circularframe {
    width: 85%;
    height: 85%;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #fff;
    transition: border-color 0.5s ease-in-out;
    position: relative;
    left: calc(20 * var(--bit-large));
    top: calc(20 * var(--bit-large));
  }

  .webcam {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .webcamOverlay {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    z-index: 100;
    position: absolute;
    background-color: rgba(5, 5, 5, 0.2);
    /* transform: translateX(-16.66%); */
  }

  .videopreview {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .controls {
    margin-top: calc(20 * var(--bit-large));
  }

  .progressbar {
    margin-top: calc(20 * var(--bit-large));
    width: 100%;
    position: relative;
    top: calc(-100% + calc(21.5 * var(--bit-large)));
  }

  .same-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}