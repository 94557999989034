@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap");

.About-main-Container {
  /* Main div */
}
.About-boarding {
  position: relative;
  padding-top: 12vh;
  height: 40vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}
.About-boarding-img {
  position: absolute;
  top: 12vh;
  width: 100%;
  z-index: -1;
  object-fit: cover;
}
.head-text-wrap {
  display: flex;
  flex-direction: column;
}
.About-boarding-text {
  width: 55vw;
  margin-left: 7.5vw;
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.53);
  font-family: Inter;
  font-size: 5.3vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.About-boarding-text2 {
  width: 60vw;
  margin-left: 7.5vw;
  padding: 1vh 1vw;
  color: #fff;

  font-family: Inter;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(50px);
}
.About-description {
  /* width: 1920px; */
  /* height: 50vh; */
  background: #fff;
}
.About-description-box {
  display: flex;
  width: 90%;
  /* height: 60vh; */
  padding: 53px 49px;
  /* margin-bottom: 2vw; */
  flex-direction: column;
  align-items: flex-start;
  margin-left: 5vw;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid rgba(110, 111, 113, 0.91);
  background: rgba(243, 230, 233, 0.61);
  box-shadow: 0px 23.782px 47.565px -5.946px rgba(40, 37, 35, 0.1),
    0px 2.973px 5.946px 0px rgba(40, 37, 35, 0.04);
}
.Pic-text-wrap {
  display: flex;
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-bottom: 2vh;
  /* gap: 52px; */
  align-self: stretch;
  border-radius: 14px;
  box-sizing: border-box;
  padding: 20px;
  border: 1px solid rgba(117, 115, 115, 0.25);
}
.Pic-Wrap {
  width: 40%;
}
.YogaSitting {
  height: 55vh;
  width: 85%;
  padding-left: 4vw;
}
.Text-wrap {
  display: flex;
  flex-direction: column;
  width: 60%;
  gap: 1vw;
}
.About-text {
  color: #000;
  text-align: justify;
  font-family: Inter;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 1vw;
  margin-right: 1.5vw;
  line-height: normal;
}
.About-video-mission {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 1920px; */
  height: 1080px;
  /* height: 100vh; */
}
.about-background-video {
  position: absolute;
  z-index: -1;
}
.Mission-Vision-Wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 4vw 0 4vw;
  width: 100%;
}
.Mission-Wrap {
  width: 20vw;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 2vw 2.5vw 2vw 2.5vw;
  background: rgba(255, 255, 255, 0.67);
  box-shadow: 0px 0px 3.107px 0px rgba(24, 20, 31, 0.06),
    0px 24.856px 55.925px 0px rgba(24, 20, 31, 0.15);
}
.Mission {
  color: #085946;
  text-align: center;
  font-size: 2vw;
  font-style: normal;
  font-weight: 700;
  line-height: 39.586px; /* 116.429% */
  letter-spacing: 0.305px;
}
.Mission-text {
  color: #000;
  text-align: center;
  font-family: "EB Garamond";
  font-size: 1.2vw;
  font-style: italic;
  font-weight: 500;
  line-height: 35px; /* 143.75% */
  padding-top: 1vw;
}
.Vision-Wrap {
  width: 20vw;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 2vw 2.5vw 2vw 2.5vw;
  background: rgba(255, 255, 255, 0.67);
  box-shadow: 0px 0px 3.107px 0px rgba(24, 20, 31, 0.06),
    0px 24.856px 55.925px 0px rgba(24, 20, 31, 0.15);
}
.Vision {
  color: #085946;
  text-align: center;
  font-size: 2vw;
  font-style: normal;
  font-weight: 700;
  /* line-height: 39.586px;  */
  letter-spacing: 0.305px;
}
.Vision-text {
  color: #000;
  text-align: center;
  font-family: "EB Garamond";
  font-size: 1.2vw;
  font-style: italic;
  font-weight: 500;
  line-height: 30px; /* 143.75% */
  padding-top: 1vw;
}
.mobilemissionvission{
  display:none;
}
@media (max-width: 916px) {
  .About-boarding-img {
    /* position: absolute; */
    top: 2vh;
    /* width: 100%; */
    z-index: -1;
    object-fit: cover;
    height: 100%;
    aspect-ratio: auto;
  }
  .About-boarding {
    position: relative;
    height: 40vh;
   
}
.About-boarding-text {
  font-size: 55px;

}
.About-boarding-text2{
  color: #FFF;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 32px;
border-radius: 10px;
background: rgba(0, 0, 0, 0.01);
backdrop-filter: blur(50px);
width: 75%;
margin-top: 2%;
}
.YogaSitting {
  height: auto;
  width: fit-content;
  padding-left: 0%;
}
.About-description {
  width:100%;
  height: auto;
}
.Pic-text-wrap {
  flex-direction: column;
}
.About-description-box {
padding: 0px;
margin-top: 5%;
width: 90%;
margin-left: 5%;
margin-right: 5%;

}
.Pic-Wrap {
  height: 50%;
}
.Text-wrap {
  width:100%;
}
.About-text {
  color: #000;
  text-align: justify;
  font-family: Inter;
  font-style: normal;
  margin-bottom: 3%;
  margin-right: 0%;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
}
.About-video-mission {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 1920px; */
  /* height: 1080px; */
  /* height: 100vh; */
  /* background: red; */
  height: 30vh;}
.Mission-Wrap {
  display: none;
}
.Vision-Wrap {
display: none;}

.About-description {
flex-direction: column;
}
.mobilemissionvission{
display:block;
width: 100%;
padding:0% 10%;
padding-bottom: 10%;
}
.mobhead{
  color: #085946;
text-align: center;
font-family: Inter;
font-size: 1.5rem;
font-style: normal;
font-weight: 700;
line-height: 39.586px; /* 116.429% */
letter-spacing: 0.305px;
margin-top: 10%;
}
.mobtext{
  color: #474747;
text-align: center;
font-family:Garamond;
font-size: 1rem;
font-style: italic;
font-weight: 500;
line-height: 35px;
}
.logo{
  height:20%;
}
}
@media (max-width: 768px) {
  .About-boarding-img {
    /* position: absolute; */
    top: 1vh;
    /* width: 100%; */
    z-index: -1;
    object-fit: cover;
    height: 100%;
    aspect-ratio: auto;
  }
  .About-boarding {
    position: relative;
    height: 40vh;
   
}
.About-boarding-text {
  font-size: 35px;

}
.About-boarding-text2{
  color: #FFF;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 32px;
border-radius: 10px;
background: rgba(0, 0, 0, 0.01);
backdrop-filter: blur(50px);
width: 75%;
margin-top: 2%;
}
.YogaSitting {
  height: auto;
  width: fit-content;
  padding-left: 0%;
}
.About-description {
  width:100%;
  height: auto;
}
.Pic-text-wrap {
  flex-direction: column;
}
.About-description-box {
padding: 0px;
margin-top: 5%;
width: 90%;
margin-left: 5%;
margin-right: 5%;

}
.Pic-Wrap {
  height: 50%;
  width: auto;
}
.Text-wrap {
  width:100%;
}
.About-text {
  color: #000;
  text-align: justify;
  font-family: Inter;
  font-style: normal;
  margin-bottom: 10%;
  margin-right: 0%;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
}
.About-video-mission {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 1920px; */
  /* height: 1080px; */
  /* height: 100vh; */
  /* background: red; */
  height: 30vh;}
.Mission-Wrap {
  display: none;
}
.Vision-Wrap {
display: none;}

.About-description {
flex-direction: column;
}
.mobilemissionvission{
display:block;
width: 100%;
padding:0% 10%;
padding-bottom: 10%;
}
.mobhead{
  color: #085946;
text-align: center;
font-family: Inter;
font-size: 1.5rem;
font-style: normal;
font-weight: 700;
line-height: 39.586px; /* 116.429% */
letter-spacing: 0.305px;
margin-top: 10%;
}
.mobtext{
  color: #474747;
text-align: center;
font-family:Garamond;
font-size: 1rem;
font-style: italic;
font-weight: 500;
line-height: 35px;
}
.logo{
  height:20%;
}
}