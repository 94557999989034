.Main-Container {
  display: flex;
  background: #FFF;
  position: relative;
}
.LMF-wrap {
  display: flex;
  flex-direction: row;
  padding-top: 12vh;
  background: #FFF;
}
.Left-container {
  flex: 1;
}
.HomeLeft-Image {
  width: 30vw;
  height: 88vh;
  flex-shrink: 0;
}
.Middle-container {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #FFF;
}
.Aivotayur-Heading {
  margin-bottom: 5vh;
  /* height: 12vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--Black, #000);
  text-align: center;
  font-family: Montserrat;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  /* line-height: 24px; */
}
.Aivotayur-description {
  display: flex;
  /* height: 29vh; */
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  color: #393434;
  text-align: center;
  
  font-size: 2.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: 69px; 
  text-transform: uppercase;
}
.Aivotayur-description2 {
  align-self: stretch;
  color: var(--Black, #000);
  text-align: center;
  
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 48px; 
}
.Assesment-Button {
  display: flex;
  /* margin-left: 30vh; */
  margin: 3vh 0;
  height: 7vh;
  padding: 11px 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #085946;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #FFF;
}

.Right-container {
  flex: 1;
}
.HomeRight-Image {
  width: 30vw;
  height: 88vh;
  flex-shrink: 0;
}
.Aivotayur-why {
  display: flex;
  flex-direction: column;
  background: #FFF;
  margin: 3rem 0;
}
.Aivotayur-why-text {
  color: var(--Black, #000);
  text-align: center;
  
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1rem;
}
.Aivotayur-why-text2 {
  color: var(--Black, #000);
  text-align: center;
  
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
  margin-top: 1vh;
}
.VP-Wrap {
  display: flex;
  flex-direction: row;
}
.Prakriti {
  width: 50%;
  display: flex;
  border: 1px solid #fff9e4;
  background: #f4e9d3;
  padding: 10px 78px 52px 78px;
  flex-direction: column;
  /* justify-content: flex-end; */
  align-items: center;
  /* gap: 47px; */
}
.Prakriti-text {
  color: var(--Black, #000);
  text-align: center;
  
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 63px; /* 126% */
}
.Prakriti-text2 {
  color: var(--Black, #000);
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
.Vikruti {
  width: 50%;
  display: flex;
  padding: 10px 78px 52px 78px;
  flex-direction: column;
  /* justify-content: flex-end; */
  align-items: center;
  /* gap: 47px; */
  border: 1px solid #fff9e4;
  background: #f3e6e9;
}
.Vikruti-text {
  color: var(--Black, #000);
  text-align: center;
  
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 63px; /* 126% */
}
.Vikruti-text2 {
  color: var(--Black, #000);
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
.Learnmore {
  display: flex;
  height: 45px;
  padding: 23px 50px;
  justify-content: center;
  margin-top: 3vh;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #085946;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #FFF;
}
.Work-wrap {
  display: flex;
  flex-direction: column;
  background: #FFF;
}
.HowWork {
  color: var(--Black, #000);
  text-align: center;
  
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 3rem;
}
.Itworks-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 3vh;
  padding: 2vh 0;
}
.work-box {
  display: flex;
  width: 24vw;
  min-height: fit-content;
  /* height: 30vh; */
  /* margin-left: 17vh; */
  padding: 30px 29px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.yoga {
  width: 49px;
  height: 49px;
}
.Text {
  color: var(--Black, #000);
  
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.para {
  color: #757373;
  
  /* font-size: 1rem; */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Ayurveda {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FFF;
}
.Ayur-Benefits {
  color: var(--Black, #000);
  text-align: center;
  
  font-size:2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 3rem;
}
.Ayur-box {
  display: flex;
  height: 40vh;
  min-height: fit-content;
  width: 83vw;
  /* margin-left: 10vh; */
  margin: 3rem 0;
  padding: 42px 43px;
  align-items: center;
  gap: 66px;
  border-radius: 12px;
  background: var(--White-white-500, #fff);
  box-shadow: 0px 23.782px 47.565px -5.946px rgba(40, 37, 35, 0.1),0px 2.973px 5.946px 0px rgba(0, 0, 0, 0.14);
}
.Doshaspic{
width: 20vw;
height: 40vh;
border-radius: 12px 0px 0px 12px;
}
.Doshastext-wrap{
    display: flex;
    flex-direction: column;
    gap:3vh;
}
.Doshastext{
color: var(--Black, #000);

font-size: 1.2rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.Doshastextbold{
color: var(--Black, #000);
text-align: justify;

font-size: 1.2rem;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.Advisory-Board{
    display: flex;
    flex-direction: column;
    background: #FFF;
}
.Advisory-text{
  color: #3F4B52;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; 
  margin: 0 5vh 5vh 5vh;
}
.Advisory-text2{
color: #256D9D;
text-align: center;
font-feature-settings: 'liga' off, 'clig' off;

font-size: 1.2rem;
font-style: normal;
font-weight: 400;
line-height: 28px; 
}
.Adviory-photo-box{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0 3rem;
}
.Photo-box1{
    height: 50vh;
    width: 20vw;
    margin-top: 2vh;
    position: relative;
}
.photo1{
    /* height: 50vh;
    width: 20vw;
    margin-left: 10vw;
    margin-top: 2vh; */
    width: 100%;
  height: 100%;
  object-fit: cover;
}
.photo1dr{
  width: 100%;
  height: 100%;
}
.Photo-box1 .caption {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #FFF;
  border-radius: 12px;
  /* background: rgba(0, 0, 0, 0.20); */
  padding: 5px 10px;
  font-size: 14px;
  z-index: 5; /* Keep this above the overlay */
}

.Photo-box1 .advisory-text-overlay {
  position: absolute;
  top: 0;
  left: 1;
  width: 100%;
  height: 100%;
  
  background: rgba(110, 111, 113, 1); /* Semi-transparent overlay */
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;

  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease; /* Smooth fade effect */
}

.Photo-box1:hover .advisory-text-overlay {
  opacity: 1; /* Show text overlay on hover */
}

.Photo-box1:hover .caption {
  opacity: 0; /* Show text overlay on hover */
}
.Leadership-Board{
    display: flex;
    flex-direction: column;
    background: #FFF;
}
.Leadership-text{
    color: #3F4B52;
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-top: 3rem;
}
.Leadership-text2{
    color: #256D9D;
text-align: center;
font-feature-settings: 'liga' off, 'clig' off;

font-size: 1.2rem;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 116.667% */
margin-top: 2vh;
}
.Leadership-photo-box{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 0 1rem;
}
.Photo-box2{
  height: 45vh;
  width: 18vw;
  position: relative;
  margin-top: 2vh;
  overflow: hidden;
}
.photo2{
  /* height: 45vh;
  width: 18vw;
  margin-left: 6vw;
  margin-top: 2vh; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color:  #256D9D;

}
.photo2hr{
  width: 100%;
  height: 100%;
}

.Photo-box2 .caption {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #FFF;
  /* background: rgba(0, 0, 0, 0.20); */
  padding: 5px 10px;
  font-size: 14px;
  z-index: 5; /* Keep this above the overlay */
}

.caption {
  /* width: 100%; */
}

.Photo-box2 .leadership-text-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  background: rgba(110, 111, 113, 1); /* Semi-transparent overlay */
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;

  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease; /* Smooth fade effect */
}

.Photo-box2:hover .leadership-text-overlay {
  opacity: 1; /* Show text overlay on hover */
}

.Photo-box2:hover .caption {
  opacity: 0; /* Show text overlay on hover */
}

.Assesment{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #FFF;
 
}
.Assesment-text1{
  color: var(--Black, #000);
text-align: center;

font-size: 2rem;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top: 4vh;
display: flex;

flex-direction: column;
justify-content: center;
flex-shrink: 0;
}
.Assesment-button{
  display: flex;
width: 20vw;
height: 8vh;
justify-content: center;
align-items: center;
margin-left: 40vw;
margin-top: 5vh;
margin-bottom: 4vh;
gap: 10px;
flex-shrink: 0;
border-radius: 8px;
background: #085946;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
color: #FFF;
font-family: var(--sds-typography-body-font-family);

}


/* styles.css */
.scrollContainer {
  scrollbar-width: none;
}

.scrollContainer::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}
