.container {
    display: flex;
    flex-direction: column;
   justify-content: center;
    align-items: center;
    background-color: #f8e9eb;
    padding: 2%;
  }
  .header{
    display: flex;
    gap:3%;
    border-radius: 100px;
background: #FFF;

box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.03) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
justify-content: space-around;
width:40%;
padding: 0.5%;


  }
  .headercontent{
    color: #000;

font-family: Inter;
font-size: 1vw;
font-style: normal;
font-weight: 600;
line-height:  150% ;
text-transform: capitalize;
  }
  .sec2{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 2.5%;
  }
  .table,.table2 {
    display: flex;
    gap: 2%;

  
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 47%;
    padding: 1.5% 2.5%;
    background: #F3ECEE;
  }
  .table2{
    width:100%;
    box-shadow: none;
    
  }
  .sec3{
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top: 2.5%;

  }
  .sec4{
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top: 2.5%;
    display: flex;
    justify-content: space-around;
    padding: 1.5%;

  }
  .column,.column2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    background: #fff;
    padding: 2%;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  .column2{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 10%;
    background: #fff;
    padding: 1%;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .theader {
    font-weight: bold;
    margin-bottom: 0.7vw;
    background: #fcfcfc;
    border: 1px solid #ccc;
    border-radius: 50%;
    padding: 5% 12%;
  }
  
  .cell {
    margin-bottom: 20px;
  }
  
  .inputBox {
    width: 90%;
    height: 30px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
  }
  .table3 {
    width: 48%;
    border-collapse: collapse;
    background-color: white;
    padding: 10px;
    box-shadow: -3px -3px 10px rgba(0, 0, 0, 0.1), 
                3px -3px 10px rgba(0, 0, 0, 0.1), 
                0 -3px 10px rgba(0, 0, 0, 0.1);
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
  }
/*   
  input {
    width: 80%;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
  } */
  
  .cellGroup2 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
    margin-bottom: 2vw;
  }








  th, td {
    border: 1px solid #ddd;
    padding: 15px;
    text-align: center;
  }
  
  th {
    background-color: #fff;
    font-weight: bold;
  }
  
  .label {
    font-weight: bold;
    display:flex;
  }
  
  .cellGroup {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
  }
  
  .inputg {
    width: 40%;
    height: 30px;
    border: 1px solid #085946;
    border-radius: 5px;
    text-align: center;
  }
  .collist{
    width: 45%;
    display: flex;
    flex-direction: column;
  }
  .colset{
    display: flex;
    width: 100%;
    margin-bottom: 1%;
  }
  .lastcolname{
    display: flex;
    color: #0F1419;

text-align: center;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 19px;
background-color: #FFF;
width:20%;
padding: 2%;
align-items: center;
justify-content: center;
  }
  .lastcoltext{
    width:70%;
   padding-left: 2%;
  }
  .lastbox{
width: 100%;
height: 100%;
padding: 3%;
  }
  
  