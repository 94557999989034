.header {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 10;
  border: 1px solid var(--Neutral-300, #eff0f6);
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
 
.header-container {
  /* border: 1px solid black; */
  width: 100%;
  padding: 0 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
 
.Headerlogo {
  /* width: 120px; */
  height: 8vh;
  margin: 2vh;
 
}
 
.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
 
.nav-link {
  width: fit-content;
  text-decoration: none;
  color: #333;
  font-weight: 500;
}
 
.nav-link:hover {
  color: #085946;
}
 
.search-profile {
  display: flex;
  align-items: center;
  /* gap: 10rem; */
}
 
.search-bar {
  border-radius: 26px;
  border: 1px solid #757373;
  background: #fff;
  /* box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05); */
  display: flex;
  /* padding: 10px 14px; */
  justify-content: center;
  align-items: center;
  text-align: left;
}
 
.profile-icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
}
.mnavbar{
  display: none;
}
.nav-link {
  color: black;
  text-decoration: none;
}



.active-nav {
  color: #085946;;
}
@media (max-width: 916px) {

  .mnavbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width:100%;
    gap:5%;
  
  
  }
  
  .mleftSection {
    display: flex;
    align-items: center;
    width:20%;
  }
  
  .mhamburger {
    font-size: 2.5rem;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .mmenu {
    position: absolute;
    top: 3rem;
    left: 0;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    display: flex
  ;
    flex-direction: column;
    gap: 2rem;
    z-index: 10;
    box-shadow: 0px 0px 2.566px 0px rgba(24, 20, 31, 0.06), 0px 20.531px 46.195px 0px rgba(24, 20, 31, 0.15);
    margin-top: 6%;
    width: 65%;
  }
  
  .mmenu a {
    text-decoration: none;
    color: black;
    padding: 0.5rem 1rem;
    border-radius: 5px;
  }
  
  .mmenu a:hover {
    background-color: #f0f0f0;
  }
  
  .mrightSection {
    display: flex;
    align-items: center;
    gap: 1rem;
    width:60%;

  }
  
  .msearchBar {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    width:80%;
  }
  
  .mlanguageButton {
    padding: 2rem 2rem;
    background-color: #006400;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width:fit-content;
    width:70%;
  }
  
  .mlanguageButton:hover {
    background-color: #004d00;
  }
  
  .mprofileImage {
    width:100%;
    height:100%;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid rgb(49, 49, 49);
    
  }
  .mprofile{
    width:10vw;
    height:9vw;
  }
  
  }

@media (max-width: 768px) {

.mnavbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width:100%;
  gap:5%;


}

.mleftSection {
  display: flex;
  align-items: center;
}

.mhamburger {
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
}

.mmenu {
  position: absolute;
  top: 3rem;
  left: 0;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  display: flex
;
  flex-direction: column;
  gap: 2rem;
  z-index: 10;
  box-shadow: 0px 0px 2.566px 0px rgba(24, 20, 31, 0.06), 0px 20.531px 46.195px 0px rgba(24, 20, 31, 0.15);
  margin-top: 6%;
  width: 65%;
}

.mmenu a {
  text-decoration: none;
  color: black;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.mmenu a:hover {
  background-color: #f0f0f0;
}

.mrightSection {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.msearchBar {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width:40%;
}

.mlanguageButton {
  padding: 0.5rem 1rem;
  background-color: #006400;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width:fit-content;
}

.mlanguageButton:hover {
  background-color: #004d00;
}

.mprofileImage {
  width:100%;
  height:100%;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid rgb(49, 49, 49);
  
}
.mprofile{
  width:10vw;
  height:9vw;
}

}