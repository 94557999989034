.Container1 {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  
    background-image: url("../../assets/Ayurveda\ herbal\ therapy\ 4k\ background.jpeg");
    
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  
  .Container1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    /* background-color: rgba(0, 0, 0, 0.5); */
    background-color: rgba(255, 255, 255, 0.15); /* Adjust the opacity for the shade */
    z-index: 1;
  }
  
  /* Ensure child content appears above the overlay */
  .Container1 > * {
    position: relative;
    z-index: 2;
  }
  
  .ayur-login-container1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30vw;
    height: 80vh;
    margin-top: 10vh;
    /* margin-left: 4vw; */
    /* margin-right: 15vw; */
    flex-shrink: 0;
    border-radius: 1rem;
    background: #f3efe9;
    text-align: center;
  }
  .form-section{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  }
  .welcome-text {
    color: var(--Black, #000);
    text-align: center;
    font-family: Inter;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 600;
    opacity: 0.6;
    margin-top: 2vh;
  }
  .Getting-Started{
      display: flex;
      flex-direction: column;
  }
  
  .Getting-Started-text {
    color: var(--Neutral-700, #4b4b4b);
    text-align: center;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
  }
  .social-signin {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
  }
  .googlelayout {
    border-radius: 0.23vw;
    border: 1px solid #20dc49;
    background: #fff;
    margin-right: 0.5vw;
  }
  .googlewrap {
    display: flex;
    flex-direction: row;
    width: 7vw;
    height: 43px;
    flex-shrink: 0;
    justify-content: space-evenly;
  }
  .googlelogo {
    width: 22px;
    height: auto;
  }
  .googletext {
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  .ayur-login-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* align-self: stretch; */
  }
  .input-group {
    margin-bottom: 10px;
  }
  
  .input-group label {
    display: block;
    font-size: 0.9rem;
    margin-bottom: 5px;
    text-align: left;
  }
  
  .input-group input {
    width: 17rem;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  .login-button {
    width: 17rem;
    padding: 12px;
    background-color: #33691E;
    color: white;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .login-button:hover {
    background-color: #33691E;
  }
  .wrapswitch{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2vh;
    
   
  }
  .checkboxtext{
    font-size: 0.9rem;
    text-align: left;
    display: flex;
    align-items: center;
    width: 84%;
    /* gap: 1vw; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: var(--Title-Small-Size, 12px);
  
  }
  .text1{
   color: var(--Neutral-700, #4B4B4B);
   font-feature-settings: 'liga' off, 'clig' off;
   font-family: Inter; 
   /* font-size: 10px; */
   font-style: normal;
   font-weight: 500;
   line-height: 20px; /* 166.667% */
   letter-spacing: 0.3px;
   /* flex: 1; */
   padding-left: 1vh;
  
  }
  .text2{
  color: var(--Error-700, #EC2D30);
  text-align: right;
  font-family: Inter;
  /* font-size: var(--Title-Small-Size, 10px); */
  font-style: normal;
  font-weight: 500;
  line-height: var(--Title-Small-Line-Height, 20px); /* 142.857% */
  letter-spacing: var(--Title-Small-Tracking, 0.1px);
  }
  /* New Class for Layout */
  
  
  /* The switch - the box around the slider */
  .switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 19px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
  opacity: 0;
  width: 0;
  height: 0;
  }
  
  /* The slider */
  .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  }
  
  .slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 14px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  }
  
  input:checked + .slider {
  /* border-radius: 12px; */
  background: var(--Primary, #76B538);
  
  }
  
  input:focus + .slider {
  box-shadow: 1px 1px 2px -1px rgba(51, 51, 51, 0.30);
  }
  
  input:checked + .slider:before {
  -webkit-transform: translateX(23px);
  -ms-transform: translateX(23px);
  transform: translateX(23px);
  }
  
  /* Rounded sliders */
  .slider.round {
  border-radius: 32px;
  }
  
  .slider.round:before {
  border-radius: 45%;
  }
  .login-text{
    color: var(--Black, #000);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 14.5px; /* 90.625% */
  }
  .login-link{
    color: #33691E;
  text-align: center;
  
  /* Text Large 1 Medium */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 122.222% */
  }
  .Insightsendcontainer {
    width: 32vw;
    height: 33vh;
   
    margin-top: 32vh;
    
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 0.5s ease;
    border-radius: 0.625rem;
    /* background: rgba(255, 242, 242, 0.10); */
    background: rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(50px);
  }
  
  .slide-dots {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .dot {
    
    width: 2.75rem;
    height: 0.25rem;
    margin: 0 5px;
   
    border-radius: 0.125rem;
    background: rgba(255, 255, 255, 0.31); 
  
    display: inline-block;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .dot.active {
    background-color:  var(--Gray-0, #FFF);
  }
  @media (max-width: 916px) {
    .Container1 {
      height: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-image: none;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
  }
  .Insightsendcontainer {
    width: 70%;
    height: fit-content;
    margin-top: 0%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 0.5s ease;
    border-radius: 0.625rem;
    /* background: rgba(255, 242, 242, 0.10); */
    background: rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(50px);
    padding: 5%;
   
  }
  .logo{display: block;}
  
  .ayur-login-container1 {
    display: flex;
    order: 2;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 55vh;
    margin-top: 0%;
    margin-left: 0%;
    margin-right: 0%;
    flex-shrink: 0;
    border-radius: 1rem;
    background: #f3efe9;
    text-align: center;
  }
  .outerInsightmob{
  
    background-image: url("../../assets/Ayurveda\ herbal\ therapy\ 4k\ background.jpeg");
      
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height:40vh;
    padding: 6%;
  }
  
   .otp-section{
    padding:5%;
   }
   .submit-btn-otp {
  width:40%
  }
  
  .otp-section h2 {
  margin-top: -10%;
  }
  .Insightsendcontainer p{
  font-size: 0.8rem;
  line-height: 22px;
  margin-top: 4%;
  }
  .h-20{
    display: none;
  }
  }
  
  @media (max-width: 768px) {
    .Container1 {
      height: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-image: none;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
  }
  .Insightsendcontainer {
    width: 70%;
    height: fit-content;
    margin-top: 0%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 0.5s ease;
    border-radius: 0.625rem;
    /* background: rgba(255, 242, 242, 0.10); */
    background: rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(50px);
    padding: 5%;
   
  }
  .logo{display: block;}
  
  .ayur-login-container1 {
    display: flex;
    order: 2;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70vh;
    margin-top: 0%;
    margin-left: 0%;
    margin-right: 0%;
    flex-shrink: 0;
    border-radius: 1rem;
    background: #f3efe9;
    text-align: center;
  }
  .outerInsightmob{
  
    background-image: url("../../assets/Ayurveda\ herbal\ therapy\ 4k\ background.jpeg");
      
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height:auto;
    padding: 6%;
  }
  
   .otp-section{
    padding:5%;
   }
   .submit-btn-otp {
  width:40%
  }
  
  .otp-section h2 {
  margin-top: -10%;
  }
  .Insightsendcontainer p{
  font-size: 0.8rem;
  line-height: 22px;
  margin-top: 4%;
  }
  .h-20{
    display: none;
  }
  }
  