.EditProfile-Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Profile-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding-top: 12vh;
}

.Profile-box-green {
  margin-top: 8vh;
  width: 83vw;
  height: 10vh;
  flex-shrink: 0;
  border-radius: 12px 12px 0px 0px;
  background: linear-gradient(90deg, rgba(8, 89, 70, 0.5) 99.21%, #fff 101.49%);
}
.Profile-box {
  width: 83vw;
  height: 65vh;
  flex-shrink: 0;
  border-radius: 0px 0px 12px 12px;
  background: #f3e6e9;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.Profile-IE-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.EditProfile-Image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5vw;
}
.avatar-wrapper {
  position: relative;
  width: 5vw;
  height: 5vw;
}
.avatar-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ccc;
}
.edit-badge {
  position: absolute;
  bottom: 1px;
  right: 1px;
  background-color: #FFF;
  color: white;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
}
.EditButton {
  border-radius: 8px;
  background: #085946;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  width: 6w;
  height: 4vh;
  padding: 23px 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  margin-right: 5vw;
}

.EditProfile-form {
  display: flex;
  justify-content: space-between;
}
.EditProfile-inputgroup1 {
  display: flex;
  flex-direction: column;
  margin-left: 5vw;
}
.EditProfile-inputgroup1 label {
  color: var(--Black, #000);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.8;
  margin-bottom: 1vh;
}
.EditProfile-inputgroup1 input {
  display: flex;
  width: 24vw;
  height: 7vh;
  padding: 13px 19px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 7.489px;
  border: 1px solid #d9d9d9;
  background: #fff;
  margin-bottom: 2vh;
}
.EditProfile-inputgroup2 {
  display: flex;
  flex-direction: column;
  margin-right: 5vw;
}
.EditProfile-inputgroup2 label {
  color: var(--Black, #000);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.8;
  margin-bottom: 1vh;
}
.EditProfile-inputgroup2 input {
  display: flex;
  width: 24vw;
  height: 7vh;
  padding: 13px 19px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 7.489px;
  border: 1px solid #d9d9d9;
  background: #fff;
  margin-bottom: 2vh;
}
.logout-container {
  display: flex;
  justify-content: space-evenly;
  width: 132px;
  height: 43px;
  padding: 0px 12px;
  align-items: center;
  margin-left: 5vw;
  margin-top: 4vw;
  gap: 13px;
  flex-shrink: 0;
  border-radius: 0.5rem;
  border: 0.5px solid rgba(117, 115, 115, 0.3);
  background: #fcfbf9;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.logout-text {
  color: #53555a;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
@media (max-width: 916px) {
  .Profile-box-green {
    margin-top: 0vh;
  }
  .EditProfile-form {
    flex-direction: column;
  }
  
.avatar-wrapper {
  width: 15vw;
  height: 15vw;
}
.EditProfile-inputgroup1 input {
  width: 90%;
}
.EditProfile-inputgroup2 {
margin-right: 0vw;
margin-left: 5vw;
}
.EditProfile-inputgroup2 input ,.EditProfile-inputgroup2 select{
  width:90%;
  height: 7vh;
}
.Profile-box {
  width: 82%;
  height: auto;
  flex-shrink: 0;
  border-radius: 0px 0px 12px 12px;
  background: #f3e6e9;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding-bottom: 10%;
}
}