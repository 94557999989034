.Test-main-Container {
  display: flex;
  flex-direction: column;
}
.Test-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 12vh;
}
.dynamic-multi-step-form {
  font-family: Arial, sans-serif;
  padding: 20px;
}

.steps-container {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  margin: 20px 0 20px 60px;
  text-align: center;
  flex: 1;
}

.step-container {
  /* width: 23%; */
  display: flex;
  flex-direction: column;
  align-items: start;
}

.step {
  width: 18%;
  text-align: center;
  background: #f1f1f1;
  border: 1px solid #ddd;
  border-radius: 50%;
  z-index: 1;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border: 1.494px solid #085946;
  background: #fff;

  width: 7vh;
  height: 7vh;
  padding: 9.427px;
  overflow: hidden;
  gap: 14.944px;
}

.step.completed {
  background: #085946;
  color: white;
  font-weight: bold;
}

.step.active {
  background: #085946;
  color: white;
  font-weight: bold;
}

.step.pending {
  border: 1.494px solid #085946;
  background: #fff;
  color: #888;
}
.progress-indicator-container {
  z-index: -1;
  top: 18%;
  left: 13%;
  width: 92%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
}

.progress-indicator {
  height: 10px;
  background: #085946;
  border-radius: 5px;
  transition: width 0.3s ease;
}

.progress-indicator.completed {
  background: #085946;
}

.question-content {
  text-align: center;
}

.navigation-buttons button {
  margin: 0 10px;
  border-radius: 8px;
  cursor: pointer;
}

.navigation-buttons button:disabled {
  cursor: not-allowed;
  /* background-color: #ccc; */
}

.step-description {
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: start;
  font-size: 14px;
  color: #555;
  margin-top: 8px;
  
}


.step-status {
  font-weight: bold;
  color: #757373;
  font-family: Inter;
}

.completed {
  color: green;
}

.pending {
  color: #757373;
}

.active {
  color: #3498db;
}

.step-text {
  color: #000;
  font-family: Inter;
}

.navigation-buttons {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  width: 100%;
  border: 1px solid #ccc;
  border-top: none;
  background: #f3e6e9;

  margin: none !important;
}
 /* Step descriptions default to hidden */
.step-description {
  display: none;
}


.desktop-only {
  display: flex; /* Always visible on desktop */
  position: static;
  background: none;
  box-shadow: none;
  padding: 0;
  border: none;
}



.mobile-only {
  display: block;
  margin-top: 10px; /* Below the progress bar */
  margin-bottom: 20px; /* Above the question content */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}



@media (max-width: 768px) {
  .steps-container {
    flex-direction: row;
    align-items: flex-start;
    margin: 0;
  }

  .step-container {
    width: 100%;
    margin-bottom: 10px;
  }

  .progress-indicator-container {
    display: none; 
  }
  .step {
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    border: 2px solid #085946;
    color: #fff;
    background: #085946;
    margin: auto;
    transition: all 0.3s ease-in-out;
  }
  .step-description {
    display: none; /* Hidden by default on mobile */
    position: relative;
    top: 100%;
    left: 25%;
    transform: translateX(-50%);
    background-color: white;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 5;
    width: max-content;
    white-space: nowrap;
  }
 
   .mobile-only{
    display: block;
   }
  
  /* Hide desktop-only descriptions on mobile */
  .desktop-only {
    display: none;
  }
 
  .step-container:hover .step-description {
    display: block;
  }
  .step:first-child:hover .step-description {
    left: 0; 
    transform: translateX(0); 
  }
  
  .step:last-child:hover .step-description {
    right: 0; 
    left: auto; 
    transform: translateX(0); 
  }
  
  

  
  .progress-bar-mobile {
    height: 5px;
    background-color: #e0e0e0;
    border-radius: 2px;
    width: 100%; 
    margin-top: 10px; 
    position: relative;
  }
  
  .progress-bar-fill {
    height: 100%;
    background-color: #085946;
    border-radius: 2px;
    width: 0; 
    transition: width 0.3s ease-in-out; 
  }
  .navigation-buttons {
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
}
/* Desktop Design (Step Description Always Visible) */
@media (min-width: 769px) {
  .step-description {
    display: flex; 
  }
  /* Show desktop descriptions always */
.desktop-only {
  display: flex; 
  position: static;
  background: none;
  box-shadow: none;
  padding: 0;
  border: none;
}

/* Hide mobile descriptions */
.mobile-only {
  display: none;
}
}