.ForgotContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  background-image: url("../../assets/Ayurveda\ herbal\ therapy\ 4k\ background.jpeg");

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.ForgotContainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-color: rgba(255, 255, 255, 0.15); /* Adjust the opacity for the shade */
  z-index: 1;
}

/* Ensure child content appears above the overlay */
.ForgotContainer > * {
  position: relative;
  z-index: 2;
}

.ayur-forgot-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30vw;
  height: 84vh;
  margin-top: 10vh;
  /* margin-left: 4vw; */
  /* margin-right: 15vw; */
  flex-shrink: 0;
  border-radius: 1rem;
  background: #f3efe9;
  text-align: center;
}
.form-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.welcome-text {
  color: var(--Black, #000);
  text-align: center;
  font-family: Inter;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 600;
  opacity: 0.6;
  margin-top: 2vh;
}
.Getting-Started {
  display: flex;
  flex-direction: column;
}

.Getting-Started-text {
  color: var(--Neutral-700, #4b4b4b);
  text-align: center;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
}
.social-signin {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}
.googlelayout {
  border-radius: 0.23vw;
  border: 1px solid #20dc49;
  background: #fff;
  margin-right: 0.5vw;
}
.googlewrap {
  display: flex;
  flex-direction: row;
  width: 7vw;
  height: 43px;
  flex-shrink: 0;
  justify-content: space-evenly;
}
.googlelogo {
  width: 22px;
  height: auto;
}
.googletext {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.ayur-forgot-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* align-self: stretch; */
}
.forgot-input-group {
  margin-bottom: 10px;
}

.forgot-input-group label {
  display: block;
  /* font-size: 0.9rem; */
  margin-bottom: 5px;
  text-align: left;
  color: var(--Black, #000);

  /* Text Large Medium 2 */
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.2px;
}

.forgot-input-group input {
  margin-top: 0.5vw;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}
.send-button {
  width: 100%;
  padding: 12px;
  background-color: #28a745;
  color: white;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #218838;
}

.login-text {
  color: var(--Black, #000);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 14.5px; /* 90.625% */
}
.login-link {
  color: #33691e;
  text-align: center;

  /* Text Large 1 Medium */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 122.222% */
}
.Forgot-Insights-container {
  width: 32vw;
  height: 33vh;

  margin-top: 32vh;

  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.5s ease;
  border-radius: 0.625rem;
  /* background: rgba(255, 242, 242, 0.1); */
  background: rgba(0, 0, 0, 0.20);
  backdrop-filter: blur(50px);
}

.slide-dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.dot {
  width: 2.75rem;
  height: 0.25rem;
  margin: 0 5px;

  border-radius: 0.125rem;
  background: rgba(255, 255, 255, 0.31);

  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dot.active {
  background-color: var(--Gray-0, #fff);
}
@media (max-width: 916px) {
  .ForgotContainer {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-image: none;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.Forgot-Insights-container {
  width: 70%;
  height: fit-content;
  margin-top: 0%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.5s ease;
  border-radius: 0.625rem;
  /* background: rgba(255, 242, 242, 0.10); */
  background: rgba(0, 0, 0, 0.20);
  backdrop-filter: blur(50px);
  padding: 5%;
 
}
.ayur-forgot-container {
  display: flex;
  order: 2;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 55vh;
  margin-top: 0%;
  margin-left: 0%;
  margin-right: 0%;
  flex-shrink: 0;
  border-radius: 1rem;
  background: #f3efe9;
  text-align: center;
  height:60vh
}
.forgotInsightmob{

  background-image: url("../../assets/Ayurveda\ herbal\ therapy\ 4k\ background.jpeg");
    
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height:40vh;
  padding: 6%;
}
.logo{
  display: block;
 }
 .change-section{
  padding:5%;
 }
 .submit-btn-change {
width:40%
}


.Forgot-Insights-container p{
font-size: 0.8rem;
line-height: 22px;
margin-top: 4%;
}
/* .input-box {

width: 100%;
}
.form-section{
width:100%;
} */
}
@media (max-width: 768px) {
  .ForgotContainer {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-image: none;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.Forgot-Insights-container {
  width: 70%;
  height: fit-content;
  margin-top: 0%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.5s ease;
  border-radius: 0.625rem;
  /* background: rgba(255, 242, 242, 0.10); */
  background: rgba(0, 0, 0, 0.20);
  backdrop-filter: blur(50px);
  padding: 5%;
 
}
.ayur-forgot-container {
  display: flex;
  order: 2;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin-top: 0%;
  margin-left: 0%;
  margin-right: 0%;
  flex-shrink: 0;
  border-radius: 1rem;
  background: #f3efe9;
  text-align: center;
  height:60vh
}
.forgotInsightmob{

  background-image: url("../../assets/Ayurveda\ herbal\ therapy\ 4k\ background.jpeg");
    
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height:40vh;
  padding: 6%;
}
.logo{
  display: block;
 }
 .change-section{
  padding:5%;
 }
 .submit-btn-change {
width:40%
}


.Forgot-Insights-container p{
font-size: 0.8rem;
line-height: 22px;
margin-top: 4%;
}
/* .input-box {

width: 100%;
}
.form-section{
width:100%;
} */
}