.footer {
  background: #F3E6E9;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-family: Arial, sans-serif;
  color: #333;
  border-top: 1px solid grey;
}

.footer-section {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-left: 5vw;
}
.Footerlogo {
  /* width: 120px; */
  height: 8vh;
  margin: 2vh;

}

.footer-logo {
  flex: 1;
  max-width: 40%;
}

.footer-logo .logo {
  width: 150px;
  height: auto;
  margin-bottom: 10px;
  /* background: #f4e9d3; */
}

.footer-logo p {
  font-size: 14px;
  line-height: 1.6;
}

.footer-nav {
    flex: 1;
    display: flex; 
    justify-content: space-evenly; 
    align-items: center;
}

.footer-nav ul {
    display: flex; 
    flex-direction: column;
    padding: 0;
    margin: 0;
    list-style: none; 
}

.footer-nav li {
  margin: 10px 0;
}

.footer-nav a {
    text-decoration: none;
    color: #333;
    font-size: 14px;
    padding: 5px 10px; 
    transition: color 0.3s ease;
}

.footer-nav a:hover {
  /* text-decoration: underline; */
}

.footer-contact {
  flex: 1;
  max-width: 30%;
  text-align: left;
}

.whatsapp-btn {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #128c7e;
  margin-bottom: 15px;
  color: #a1a1aa;
  font-family: Inter;
  font-size: 0.8em;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 122.222% */
}

.whatsapp-btn img {
  width: 20px;
  margin-right: 10px;
}
.newsletter-text {
  color: var(--Black, #000);
  font-family: Inter;
  font-size: 0.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 122.222% */
  letter-spacing: 3px;
}
/* .newsletter h4 {
    font-size: 16px;
    margin-bottom: 10px;
  } */

.newsletter form {
  display: flex;
  flex-direction: column;
}

.newsletter input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
 width: 15vw;
}

.newsletter button {

cursor: pointer; 
  display: flex;
  width: 10vw;
  padding: 13px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #085946;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #FFF;
}

.newsletter button:hover {
  background-color: #0d6d5c;
}

@media (max-width: 768px) {
  .footer-section {
    margin-left: 0vw;

    flex-direction: column;
  }
  .footer-logo {
    flex: 1 1;
    max-width: 100%;
    width: 100%;
}
.footer-nav ul {
flex-direction: row;
gap:10px
}
.footer-nav a {
  padding: 0px;
  color: #18141f;
  font-size: 2.78vw;
  font-weight: bold;
}
.footer-nav {
margin-top: 7%;
}
.footer-contact{
  max-width: 100%;
  margin-top: 9%;

}
.newsletter-text {
  font-size: 1rem;
}
}