.Contactus-main-container {
  display: flex;
  flex-direction: column;
  transition: all 500ms ease-in-out;
}
.LF-WRAP {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 12vh;
  height: 100vh;
  min-height: fit-content;
}
.left-container {
  width: 55%;
  height: 100%;
}
.cimage {
  width: 100%;
  height: 100vh;
  /* width: 58vw; */
}
.right-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0 2% 0 4%;
  width: 45%;
  flex-shrink: 0;
  background: linear-gradient(270deg, rgba(8, 89, 70, 0.9) -10.69%, #CFCCCC 64.95%);
}
.rtext1 {
  /* display: flex; */
  color: #111d15;
  font-family: Inter;
  font-size: 2vw;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  justify-content: flex-start;
  margin-top: 10%;
}
.rtext2 {
  color: #FFF;
  font-family: Inter;
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.18px;
  margin: 1.5vw 0 1.5vw 0;
}
.form-container {
  display: flex;
  justify-content: center;
  align-items: start;
  margin: 3vh 0;
  font-size: small;
  flex-direction: column;
  width: 70%;
}
.contact-form {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 1.5vw;
}
.contact-form input,
.contact-form textarea {
  display: flex;
  width: 100%;
  padding: 2% 5%;
  align-items: center;
  /* gap: 24px; */
  border-radius: 7.179px;
  border: 1.795px solid #f3f3f3;
  background: #fff;
  box-shadow: 0px 5.326px 6.658px 0px rgba(0, 0, 0, 0.07);
  color: #666;
  font-family: Inter;
}
.contact-form button{
display: flex;
height: 7vh;
width: 100%;
padding: 10px 20px 10px 20px;
justify-content: center;
align-items: center;
gap: 10px;
align-self: stretch;
border-radius: 8px;
background: #085946;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
color: #FFF;
}
.ADF-Wrap{
  display: flex;
  flex-direction: column;
  background: #FFF;
  padding: 5vw;
}
.Adrees-box{
  display: flex;
  height: fit-content;
  min-height: fit-content;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid rgba(102, 102, 102, 0.64);
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);

  width: 100%;
}
.india{
  color: var(--Black, #000);
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.itext{
  color: var(--Black, #000);
font-family: Inter;
font-size: 1.2rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.itext2{
  color: #666;
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 400;
/* line-height: normal; */
}
.irwrap{
  display: flex;
  flex-direction: row;
}
.location{
  /* width: 5vw; */
  height: 3vh;
}
.itext3{
  color: var(--Black, #000);
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-left: 1vw;
}
.irwarp2{
  display: flex;
  flex-direction: row;
}
.irwarp3{
  display: flex;
  flex-direction: row;

}
.mail{
  height: 3vh;
}
/* .FAQ-WRAP{
  display: flex;
  flex-direction: column;
}
.FAQ-text{
  color: var(--Black, #000);
  text-align: center;
  font-family: Inter;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.6px;
  margin-top: 3vh;
}

.faq-section {
  font-family: Arial, sans-serif;
  margin: 20px auto;
}

.faq-item {
  padding: 15px 10px;
}

.faq-item:last-child {
  border-bottom: none;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  color: #333;
  border-radius: 12px;
  background: rgba(126, 215, 194, 0.3);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);

  width: 50vw;

  padding: 25px;

  align-items: center;
  gap: 40px;
  flex-shrink: 0;
}


.faq-answer {
  font-size: 0.9rem;
  color: #555;
  margin-top: 10px;
  line-height: 1.6;
  display: flex;
  justify-content: space-between;
  align-items: center;
padding: 2%;
  border-radius: 12px;
  background: #E7F6EC;
  width: 50vw;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
  
}

.plus-icon {
  font-size: 1.2rem;
  color: #ffffff;
  border-radius: 0px 8px 8px 0px;
  background: var(--Black, #152329);
  backdrop-filter: blur(7.5px);
  display: flex;
  width: 0.3vw;

  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.minus-icon {
  cursor: pointer;
  font-size: 1.2rem;
  color: #ffffff;
  border-radius: 0px 8px 0px 0px;
  background: var(--Black, #152329);
  backdrop-filter: blur(7.5px);
  display: flex;
  width: 0.3vw;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  margin-bottom: 12vh;
}

.minus-icon:hover {
  color: #ff0000;
  transition: all 300ms ease-in-out;
} */
@media (max-width: 916px) {

  .LF-WRAP {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    padding-top: 1vh;
    min-height: fit-content;
  }
  .left-container {
    width:100%;
    height:auto;
  }
  .cimage {
    width: 100%;
    height: auto;
    /* width: 58vw; */
  }
  .right-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0%;
    width: 100%;
    flex-shrink: 0;
    background: linear-gradient(270deg, rgba(8, 89, 70, 0.9) -10.69%, #CFCCCC 64.95%);
  }
  .rtext1{
    font-size: 1.8rem;
  }
  .rtext2{
    font-size: 1rem;
  }
  .form-container{
    width:85%
  }
  .contact-form{
    margin-top: 2%;
    gap: 5vw;
  }
  .contact-form input, .contact-form textarea {
    padding:5%;
  }
  .ADF-Wrap{
    padding: 7vw;
    padding-bottom: 10vw;
  }
  /* .faq-section{
    width: 100%;
  }
  .faq-item {
    padding: 4% 7%;
    width: 100%;
}
.faq-question {
  width: auto;
  border-radius: 12px;
padding: 5%;
}
.faq-answer {
  width: 100%;
} */
}